body {
  font-family: 'Roboto', sans-serif;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}